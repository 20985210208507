import { ref, watch, computed } from "@vue/composition-api";
import { title } from "@core/utils/filter";
import store from "@/store";

// Notification
/* import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' */

export default function useUsersList() {
  // Use toast
  /* const toast = useToast() */

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: true },
    { key: "type", sortable: true },
    { key: "url", sortable: true },
    { key: "slug", sortable: true },
    { key: "status", sortable: true },
    { key: "seo", sortable: true },
    { key: "created_by", sortable: true },

    /* { key: 'role', sortable: true },
                                                                {
                                                                  key: 'currentPlan',
                                                                  label: 'Plan',
                                                                  formatter: title,
                                                                  sortable: true,
                                                                }, */
    { key: "created_at", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(15);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id_customer");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchPages = (ctx, callback) => {
    store
      .dispatch("cms-pages/fetchPages", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        /* role: roleFilter.value,
                                                                                                                                plan: planFilter.value, */
        status: statusFilter.value,
      })
      .then((response) => {
        const { data, total } = response.data;
        callback(data);
        totalUsers.value = total;
      })
      .catch(() => {
        /* toast({
                                                                                                                                  component: ToastificationContent,
                                                                                                                                  props: {
                                                                                                                                    title: 'Error fetching users list',
                                                                                                                                    icon: 'AlertTriangleIcon',
                                                                                                                                    variant: 'danger',
                                                                                                                                  },
                                                                                                                                }) */
      });
  };

  const setPublish = (data) => {
    store
      .dispatch("cms-pages/setPagePublish", {
        page_id: data.id,
        is_publish: data.publish === 0 ? 1 : 0,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        refetchData();
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };
  const resolveStatusActiveIcon = (role) => {
    if (role === "1") return "CheckIcon";
    if (role === "0") return "XIcon";
    return "UserIcon";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchPages,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveStatusActiveIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    setPublish,
  };
}
