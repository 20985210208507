<template>
  <div>
    <add-new
      :is-add-new-page-sidebar-active.sync="isAddNewPageSidebarActive"
      :data="pageData"
      :type-form="typeForm"
      @refetch-data="refetchData"
    />

    <seo
      :is-add-seo-sidebar-active.sync="isAddSeoSidebarActive"
      :page-id="pageId"
      :data="seo"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <page-filter
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="addNew()">
                <span class="text-nowrap">Add Page</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        id="overlay-background"
        spinner-variant="primary"
        :show="loadTable"
        variant="transparent"
        opacity="1.00"
        blur="0.5em"
        rounded="sm"
      >
        <b-table
          ref="refUserListTable"
          :busy.sync="loadTable"
          class="position-relative"
          :items="fetchPages"
          responsive
          :fields="tableColumns"
          primary-key="id_customer"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          :bordered="true"
          :hover="true"
        >
          <template #cell(nama_customer)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.nama_customer)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                />
              </template>
              <b-link>
                {{ data.item.nama_customer }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: status -->
          <template #cell(gender)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.gender === null ? 'N/A' : data.item.gender
              }}</span>
            </div>
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.is_draft === 1" variant="warning"> Draft </b-badge>
            <div v-else>
              <b-badge v-if="data.item.publish === 1" variant="success"> Publish </b-badge>
              <b-badge v-else variant="info"> Unpublish </b-badge>
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(seo)="data">
            <b-badge v-if="data.item.seo === null" variant="light-warning"> Default </b-badge>
            <b-badge v-else variant="light-success"> Custom </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="show(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.can_edit === 1" @click="edit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openSeoForm(data)">
                <feather-icon icon="LayersIcon" />
                <span class="align-middle ml-50">SEO</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item @click="openComponentForm(data)">
								<feather-icon icon="LayersIcon" />
								<span class="align-middle ml-50">Component</span>
							</b-dropdown-item> -->
              <b-dropdown-item @click="navToComponentCreate(data.item)">
                <feather-icon icon="LayersIcon" />
                <span class="align-middle ml-50">Component</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.can_edit === 1" @click="setPublish(data.item)">
                <feather-icon icon="LayersIcon" />
                <span class="align-middle ml-50"
                  >Set {{ data.item.publish === 0 ? 'Publish' : 'Not Publish' }}</span
                >
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.can_edit === 1" @click="onDestroy(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <create-component :is-show="showForm" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'
import PageFilter from '../components/Filter.vue'
import usePages from './usePages'
import usePageModule from '../usePageModule'
import AddNew from '../components/Create.vue'
import Seo from '../components/SEO.vue'

export default {
  components: {
    PageFilter,
    AddNew,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    Seo,
    vSelect,
  },
  setup(props, context) {
    const PAGE_STORE_MODULE_NAME = 'cms-pages'

    // Register module
    if (!store.hasModule(PAGE_STORE_MODULE_NAME)) {
      store.registerModule(PAGE_STORE_MODULE_NAME, usePageModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_STORE_MODULE_NAME)) {
        store.unregisterModule(PAGE_STORE_MODULE_NAME)
      }
    })

    const isAddNewPageSidebarActive = ref(false)
    const isAddSeoSidebarActive = ref(false)
    const pageId = ref(0)
    const seo = ref(null)

    const openSeoForm = data => {
      pageId.value = data.item.id
      seo.value = data.item.seo
      isAddSeoSidebarActive.value = true
      // console.log(data, seo.value);
    }

    const showForm = ref(false)

    const openComponentForm = data => {
      console.log(data)
      showForm.value = true
    }

    const navToComponentCreate = data => {
      // console.log(data)
      context.root.$router.push({
        name: 'content-management-compenents',
        params: { page_id: data.id, data },
      })
    }

    const roleOptions = [
      { label: 'Pria', value: 'pria' },
      { label: 'Perempuan', value: 'perempuan' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveStatusActiveIcon,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      setPublish,
    } = usePages()
    const typeForm = ref('add')
    const pageData = ref(null)

    const addNew = () => {
      typeForm.value = 'add'
      isAddNewPageSidebarActive.value = true
    }

    const edit = data => {
      typeForm.value = 'edit'
      pageData.value = data
      isAddNewPageSidebarActive.value = true
    }

    const show = data => {
      typeForm.value = 'show'
      pageData.value = data
      isAddNewPageSidebarActive.value = true
    }

    const onDestroy = id => {
      store.dispatch('cms-pages/deletePage', id).then(() => {
        refetchData()
      })
    }
    return {
      onDestroy,
      // Sidebar
      loadTable: false,
      isAddNewPageSidebarActive,

      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveStatusActiveIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      isAddSeoSidebarActive,
      pageId,
      openSeoForm,
      seo,
      typeForm,
      addNew,
      edit,
      show,
      pageData,
      formatDate,
      openComponentForm,
      showForm,

      navToComponentCreate,
      setPublish,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
