<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{
            props.typeForm === 'add'
              ? 'Add New Page'
              : props.typeForm === 'edit'
              ? 'Edit Page'
              : 'Detail Page'
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Nama" rules="required">
            <b-form-group label="Nama" label-for="name">
              <b-form-input
                id="name"
                v-model="pageData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nama Halaman"
                :readonly="!canEdit"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Jenis" label-for="type">
            <v-select
              id="type"
              v-model="pageData.type"
              :options="['Pria', 'Perempuan']"
              :clearable="false"
              placeholder="Jenis Halaman"
              :disabled="!canEdit"
            />
          </b-form-group>
          <validation-provider #default="validationContext" name="URL" rules="required">
            <b-form-group label="URL" label-for="url">
              <b-form-input
                id="url"
                v-model="pageData.url"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="e.g: /home"
                :readonly="!canEdit"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="SLUG" rules="">
            <b-form-group label="Slug" label-for="slug">
              <b-form-input
                id="slug"
                v-model="pageData.slug"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Slug For Url"
                :readonly="!canEdit"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div v-if="props.typeForm !== 'show'" class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPageSidebarActive',
    event: 'update:is-add-new-page-sidebar-active',
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: 'add',
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankPageData = {
      name: null,
      type: null,
      url: null,
      slug: null,
    }

    const canEdit = ref(true)

    const pageData = props.data ? ref(props.data) : ref(JSON.parse(JSON.stringify(blankPageData)))
    const resetpageData = () => {
      pageData.value = JSON.parse(JSON.stringify(blankPageData))
    }

    watch(
      () => props.isAddNewPageSidebarActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          pageData.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          pageData.value = props.data
        } else {
          canEdit.value = true
          pageData.value = blankPageData
        }
      }
    )

    const onSubmit = () => {
      console.log(props.typeForm)
      if (props.typeForm === 'add') {
        store.dispatch('cms-pages/addPage', pageData.value).then(response => {
          emit('refetch-data')
          emit('update:is-add-new-page-sidebar-active', false)
        })
      } else {
        const form = {
          name: pageData.value.name,
          type: pageData.value.type,
          url: pageData.value.url,
          slug: pageData.value.slug,
          id: pageData.value.id,
        }
        console.log(form)
        store.dispatch('cms-pages/editPage', form).then(response => {
          emit('refetch-data')
          emit('update:is-add-new-page-sidebar-active', false)
        })
      }
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetpageData)

    return {
      tglLahir: null,
      pageData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
