<template>
  <b-sidebar id="add-seo-sidebar" :visible="isAddSeoSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-seo-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add SEO</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Title" rules="">
            <b-form-group label="Title" label-for="title">
              <b-form-input id="title" v-model="seoData.title" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Title" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider #default="validationContext" name="Meta Description" rules="">
            <b-form-group label="Meta Description" label-for="meta-description">
              <b-form-textarea id="meta-description" v-model="seoData.meta_description"
                :state="getValidationState(validationContext)" trim placeholder="Meta Description" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Meta Keyword" rules="">
            <b-form-group label="Meta Keyword" label-for="meta-keyword">
              <b-form-textarea id="meta-keyword" v-model="seoData.meta_keyword"
                :state="getValidationState(validationContext)" trim placeholder="Meta Keyword" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Company -->
          <validation-provider #default="validationContext" name="OG Url" rules="">
            <b-form-group label="OG URL" label-for="og_title">
              <b-form-input id="og_title" v-model="seoData.og_url" :state="getValidationState(validationContext)" trim
                placeholder="OG URL" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider #default="validationContext" name="OG Title" rules="">
            <b-form-group label="OG Title" label-for="og_title">
              <b-form-input id="og_title" v-model="seoData.og_title" :state="getValidationState(validationContext)" trim
                placeholder="OG Title" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="OG Description" rules="">
            <b-form-group label="OG Description" label-for="og_description">
              <b-form-textarea id="og_description" v-model="seoData.og_description"
                :state="getValidationState(validationContext)" trim placeholder="OG Description" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="OG Image" rules="">
            <b-form-group label="OG Image" label-for="og_image">
              <b-form-input id="og_image" v-model="seoData.og_image" :state="getValidationState(validationContext)" trim
                placeholder="OG Image" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="OG Type" rules="">
            <b-form-group label="OG Type" label-for="og_type">
              <b-form-input id="og_type" v-model="seoData.og_type" :state="getValidationState(validationContext)" trim
                placeholder="OG Type" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inject, { computed, ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { get } from "@vueuse/shared";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";

export default {
  components: {
    Password: () => import("vue-password-strength-meter"),
    DatePicker: () => import("vue2-datepicker"),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddSeoSidebarActive",
    event: "update:is-add-seo-sidebar-active",
  },
  props: {
    isAddSeoSidebarActive: {
      type: Boolean,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankSeoData = {
      title: null,
      meta_description: null,
      meta_keyword: null,
      og_url: null,
      og_title: null,
      og_description: null,
      og_image: null,
      og_type: null,
    };

    const seoData = ref({});
    seoData.value = props.data ? props.data : { ...blankSeoData };

    watch(
      () => props.isAddSeoSidebarActive,
      (newVal, oldVal) => {
        console.log(newVal, "newVal");
        seoData.value = props.data !== null ? props.data : { ...blankSeoData };
        seoData.value.page_id = props.pageId;

        console.log(seoData, props.data, blankSeoData);
      }
    );
    const resetSeoData = () => {
      seoData.value = { ...blankSeoData };
    };
    const onSubmit = () => {
      console.log(seoData);
      store.dispatch("cms-pages/addSeo", seoData.value).then((response) => {
        emit("refetch-data");
        emit("update:is-add-seo-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetSeoData);

    return {
      tglLahir: null,
      seoData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
